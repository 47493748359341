require([
    'services',
    'jquery',
    'semantic',
    'ch_selectors',
    'ch_load_mask',
    'ch_form_validation',
    'ch_semantic_responsive',
    'ch_footer',
    'ch_scrollto',
], function () {
    $(function () {
        var ourBenefits = $('#our_benefits');
        if (ourBenefits.length > 0) {
            var benefits_section = $(ourBenefits).offset().top - 100;
            var how_section = $._('how_section').offset().top - 80;
            var partners_section = $._('partners_section').offset().top - 80;
            var form_section = $._('form_section').offset().top - 140;

            $._('scroll_action').on('click', function (e) {
                e.preventDefault();

                var type = $(this).data('type');
                var scroll_destination;

                if (type === 'benefits' || type === 'learn_more') {
                    scroll_destination = benefits_section;
                } else if (type === 'how') {
                    scroll_destination = how_section;
                } else if (type === 'partners') {
                    scroll_destination = partners_section;
                }

                CH.scrollTo(scroll_destination);
            });

            $._('get_started').on('click', function (e) {
                e.preventDefault();
                CH.scrollTo(form_section);
            });

            // @todo - this page is still not all "new semantic" but the form *is* using new semantic styles, so we must validate it as such
            $('[name=hub]').dropdown();
            CH.semantic_form_validation = true;

            var validator = CH.validate('form_RegistrationProvider');

            // if (typeof $.fn.cycle !== 'undefined') {
            //     $._('banner_slideshow').cycle({
            //         log: false,
            //         speed: 1000,
            //     });
            // }
        }
    });
});
